import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import snickersHungerInsuranceLogo from "../../images/snickers-hunger-insurance.png"

const Logo = () => {
  return (
    <>
      <Link className="d-inline-block" to="/snickers-hunger-insurance/">
        <figure className="mb-0">
          {/* <StaticImage
            src="../../images/snickers-hunger-insurance.png"
            alt="Snickers Logo"
            quality={100}
          /> */}
          <img
            src={snickersHungerInsuranceLogo}
            alt="Snickers Logo"
            className="img-fluid"
          />
        </figure>
      </Link>
    </>
  )
}

export default Logo
