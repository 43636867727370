import React from "react"

import TabLinks from "./tabLinks"
import NoobMoveTab from "./tab-content/noobMoveTab"
import EarlyDeathTab from "./tab-content/earlyDeathTab"
import RageQuitTab from "./tab-content/rageQuitTab"
import SaveSnafuTab from "./tab-content/saveSnafuTab"
import LootLossTab from "./tab-content/lootLoss"
import OtherBlundersTab from "./tab-content/otherBlunders"

const HomeTabs = () => {
  return (
    <>
      <div className="text-center nav-tabs-header">
        <h2 className="text-uppercase">Things We Cover:</h2>
        <TabLinks />
      </div>
      <div className="tab-content text-center text-white" id="myTabContent">
        <NoobMoveTab />
        <EarlyDeathTab />
        <RageQuitTab />
        <SaveSnafuTab />
        <LootLossTab />
        <OtherBlundersTab />
      </div>
    </>
  )
}

export default HomeTabs
