import React from "react"
import ChatButton from "../chatBtn"
import iconRandomBlunders from "../../../images/en/icon-random-blunders.png"

const OtherBlundersTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="other-blunders"
        role="tabpanel"
        aria-labelledby="other-blunders-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-random-blunders.png"
              alt="Other Blunders"
              quality={100}
            /> */}
            <img
              src={iconRandomBlunders}
              alt="Other Blunders"
              className="img-fluid"
            />
          </figure>
          {/* <h3 className="text-uppercase">Other Blunders</h3> */}
          <p>
            Did you make a hunger-induced gaming blunder that isn't covered by
            one of our policies? Or maybe you can't figure out which policy
            applies and you're tired of trying? Our agents can help with that
            too!
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="Chat with an agent now" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherBlundersTab
