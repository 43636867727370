import React from "react"

const TabLinks = () => {
  return (
    <>
      <div role="tablist">
        <ul className="nav nav-tabs nav-fill" id="myTab">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="noob-move-tab"
              data-bs-toggle="tab"
              data-bs-target="#noob-move"
              type="button"
              role="tab"
              aria-controls="Noob Move"
              aria-selected="true"
            >
              Noob Move
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="early-death-tab"
              data-bs-toggle="tab"
              data-bs-target="#early-death"
              type="button"
              role="tab"
              aria-controls="Early Death"
              aria-selected="false"
            >
              Early Death
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="rage-quit-tab"
              data-bs-toggle="tab"
              data-bs-target="#rage-quit"
              type="button"
              role="tab"
              aria-controls="Rage Quit"
              aria-selected="false"
            >
              Rage Quit
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="save-snafu-tab"
              data-bs-toggle="tab"
              data-bs-target="#save-snafu"
              type="button"
              role="tab"
              aria-controls="Save Snafu"
              aria-selected="false"
            >
              Save Snafu
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="loot-loss-tab"
              data-bs-toggle="tab"
              data-bs-target="#loot-loss"
              type="button"
              role="tab"
              aria-controls="Loot Loss"
              aria-selected="false"
            >
              Loot Loss
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="other-blunders-tab"
              data-bs-toggle="tab"
              data-bs-target="#other-blunders"
              type="button"
              role="tab"
              aria-controls="Other Blunders"
              aria-selected="false"
            >
              Other Blunders
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default TabLinks
