import React from "react";
import ChatButton from "../chatBtn";
import iconEarlyDeath from "../../../images/en/icon-early-death.png";

const EarlyDeathTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="early-death"
        role="tabpanel"
        aria-labelledby="early-death-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-early-death.png"
              alt="Early Death"
              quality={100}
            /> */}
            <img src={iconEarlyDeath} alt="Early Death" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Early Death</h3> */}
          <p>
            Were you the first player to die in your hundred player Battle
            Royale? Killed by the easiest boss before you could land even a
            glancing blow? Died before your team even went into battle? We cover
            premature video game deaths of all kinds. So turn your
            embarrassingly early exit into a satisfying Snickers!
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="Chat with an agent now" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarlyDeathTab;
