import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import homeBanner from "../../images/hero-banner.jpg"

const HomeContent = () => {
  return (
    <>
      <div className="content text-white">
        <div>
          <figure class="mb-3 mb-lg-4">
            {/* <StaticImage
              src="../../images/hero-banner.jpg"
              alt="Hero Banner"
              quality={100}
            /> */}
            <img src={homeBanner} className="img-fluid" alt="Hero Banner" />
          </figure>
        </div>
        <div className="page-heading">
          <h1 className="text-uppercase">
            Your gaming blunders can earn you Snickers.
          </h1>
        </div>
        <p className="text-md mb-0">
          If you've made a video game blunder due to hunger, Snickers has you
          covered. With our innovative Hunger Insurance offering, you can turn
          your gaming mishaps into a delicious Snickers.
        </p>
        {/* <p className="text-md mb-0">
          Find the policy that best fits your situation. Or talk to one of our
          agents to see if your hunger accident is covered. Our agents are
          standing by to assist you!
        </p> */}
      </div>
    </>
  )
}

export default HomeContent
