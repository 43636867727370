import React from "react"
import ChatButton from "../chatBtn"
import iconNoobMove from "../../../images/en/icon-noob-move.png"

const NoobMoveTab = () => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="noob-move"
        role="tabpanel"
        aria-labelledby="noob-move-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-noob-move.png"
              alt="Noob Move"
              quality={100}
            /> */}
            <img src={iconNoobMove} alt="Noob Move" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Noob Move</h3> */}
          <p>
            Have you made a mistake so boneheaded it would make the noobiest of
            noobs feel embarrassed for you? We cover those. Our agents are
            standing by right now to help you process your claim with minimal
            shaming.
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="Chat with an agent now" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoobMoveTab
