import React from "react"
import ChatButton from "../chatBtn"
import iconSaveSnafu from "../../../images/en/icon-save-snafu.png"

const SaveSnafuTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="save-snafu"
        role="tabpanel"
        aria-labelledby="save-snafu-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-save-snafu.png"
              alt="Save Snafu"
              quality={100}
            /> */}
            <img src={iconSaveSnafu} alt="Save Snafu" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Save Snafu</h3> */}
          <p>
            Did you erase days worth of gaming progress with an ill-timed
            restart? Or by trusting your game to an ill-tempered little brother?
            Or were you just hungry and didn't save before doing something
            colossally stupid? We cover this. And while we can't in any way help
            you recover what you've lost, we can help you get something
            delicious to muffle your sobs - a Snickers.
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="Chat with an agent now" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default SaveSnafuTab
